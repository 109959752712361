<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-11-09 14:18:02
 * @LastEditors: Zhongyu
 * @LastEditTime: 2021-11-09 17:16:42
-->
<template>
  <div class="looklogistics">
    <el-button
      type="primary"
      @click="query_getLogistics"
      style="margin-top: 10px"
      >查看物流
    </el-button>
    <el-dialog title="查看物流" :visible.sync="dialogVisible" width="960px">
      <!-- -->
      <div style="display: flex;position: absolute;bottom: 50px;left: 52px;">
        <div>物流名称：{{ logisticsName }}</div>
        <div style="margin-left: 30px;">物流编号：{{ logisticsNum }}</div>
      </div>
      <div>
        <el-timeline :reverse="reverse" v-if="getLogistics.length > 0">
          <el-timeline-item
            v-for="(activity, index) in getLogistics"
            :key="index"
            :timestamp="activity.time"
          >
            <p class="date">{{ activity.time }}</p>
            {{ activity.content }}
          </el-timeline-item>
        </el-timeline>
        <p style="text-align:center; font-size:20px;" v-else>
          暂无物流信息
        </p>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="dialogVisible = false"
          >关 闭</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: ["logisticsNum", "logisticsName"],
  data() {
    return {
      dialogVisible: false,
      reverse: true,
      getLogistics: []
    };
  },
  computed: {},
  watch: {},
  methods: {
    query_getLogistics() {
      this.$api
        .getLogistics({
          logisticsNumber: this.logisticsNum
          // logisticsNumber: 12345678,
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.dialogVisible = true;
            this.getLogistics = data;
            console.log("this.getLogistics", this.getLogistics);
          }
        });
    }
  },
  created() {},
  mounted() {},
  beforeCreate() {},
  beforeMount() {},
  beforeUpdate() {},
  updated() {},
  beforeDestroy() {},
  destroyed() {},
  activated() {}
};
</script>
<style lang="less" scoped></style>

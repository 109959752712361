<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-05 21:08:06
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-10 11:43:59
-->
<template>
  <div class="install-details">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          path: '/personal-center/install/reorder'
        }"
      >
        订单数据统计
      </el-breadcrumb-item>
      <el-breadcrumb-item>订单详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="order_details" v-if="!show_accessories">
      <div class="middle">
        <div class="title">原安装信息</div>
        <div class="install-body">
          <div class="install-body-list" v-show="data.status <= 2">
            <table>
              <thead>
                <tr class="list-header">
                  <th class="header-item item-310">产品信息</th>
                  <th class="header-item item-360">客户信息</th>
                  <th class="header-item item-215">订单类型</th>
                  <th class="header-item item-255">结算收益</th>
                </tr>
              </thead>
            </table>
            <table>
              <tbody class="list-line-item" v-for="i in 1" :key="i">
                <tr class="sep-row">
                  <td colspan="4"></td>
                </tr>
                <tr>
                  <td colspan="4" class="line-item-colspan">
                    <span class="order-number">
                      订单号：{{ data.orderNum }}
                    </span>
                    <span class="time">
                      安装时间：{{ data.serviceTime.split(' ')[0] }}
                    </span>
                  </td>
                </tr>
                <tr>
                  <td class="line-item item-310">
                    <div class="img-or-name">
                      <img :src="$utils.picturePrefix(data.productImage)" />
                      <span>
                        {{ data.productName }}
                      </span>
                    </div>
                  </td>
                  <td class="line-item item-360">
                    <div class="real-name">
                      {{ $utils.formattedName(data.clientName) }}
                    </div>
                    <div class="address">
                      {{ data.clientAddress }}********
                      <p>****</p>
                    </div>
                    <div class="phone">
                      {{ $utils.formattedPhone(data.clientPhone) }}
                    </div>
                  </td>
                  <td class="line-item item-215">
                    <div class="order-type">
                      {{ $utils.orderTypeName(data.type) }}
                    </div>
                  </td>
                  <td class="line-item item-255">
                    <div class="order-money">
                      基础金额：￥{{ $utils.formatMoney(data.money) }}
                    </div>
                    <div class="order-money">
                      激励金额：￥{{ $utils.formatMoney(data.incentiveMoney) }}
                    </div>
                    <div class="order-money">
                      追加金额：￥{{ $utils.formatMoney(data.additionalMoney) }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="install-body-list" v-show="data.status > 2">
            <table>
              <thead>
                <tr class="list-header">
                  <th class="header-item item-310">产品信息</th>
                  <th class="header-item item-300">客户信息</th>
                  <th class="header-item item-145">订单类型</th>
                  <th class="header-item item-175">结算收益</th>
                  <th class="header-item item-210" v-if="data.status == 3">
                    签收时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 4">
                    预约时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 5">
                    完成时间
                  </th>
                  <th class="header-item item-210" v-if="data.status == 6">
                    结算时间
                  </th>
                </tr>
              </thead>
            </table>
            <tbody class="list-line-item">
              <tr class="sep-row">
                <td colspan="5"></td>
              </tr>
              <tr>
                <td colspan="5" class="line-item-colspan">
                  <span class="order-number">
                    订单号：{{ data.orderNum }}
                  </span>
                  <span class="time">
                    安装时间：{{ data.serviceTime.split(' ')[0] }}
                  </span>
                </td>
              </tr>
              <tr>
                <td class="line-item item-310">
                  <div class="img-or-name">
                    <img :src="$utils.picturePrefix(data.productImage)" />
                    <span>
                      {{ data.productName }}
                    </span>
                  </div>
                </td>
                <td class="line-item item-300">
                  <div class="real-name">
                    {{ data.clientName }}
                  </div>
                  <div class="address">
                    <p style="margin-bottom: 5px">
                      客户地址：{{ data.clientAddress }}
                    </p>
                    <p>详细地址：{{ data.clientAddressDetail }}</p>
                  </div>
                  <div class="phone">
                    {{ data.clientPhone }}
                  </div>
                </td>
                <td class="line-item item-145">
                  <div class="order-type">
                    {{ $utils.orderTypeName(data.type) }}
                  </div>
                </td>
                <td class="line-item item-175">
                  <div class="order-money">
                    基础金额：￥{{ $utils.formatMoney(data.money) }}
                  </div>
                  <div class="order-money">
                    激励金额：￥{{ $utils.formatMoney(data.incentiveMoney) }}
                  </div>
                  <div class="order-money">
                    追加金额：￥{{ $utils.formatMoney(data.additionalMoney) }}
                  </div>
                </td>
                <td class="line-item item-210" v-if="data.status == 3">
                  <div class="make-date">{{ data.receivingTime }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 4">
                  <div class="make-date">{{ data.appointmentDate }}</div>
                  <div class="make-time">{{ data.appointmentTimeS }}</div>
                  <div class="make-time">至</div>
                  <div class="make-time">{{ data.appointmentTimeE }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 5">
                  <div class="make-date">{{ data.completeTime }}</div>
                </td>
                <td class="line-item item-210" v-if="data.status == 6">
                  <div class="make-date">{{ data.auditTime }}</div>
                </td>
              </tr>
            </tbody>
          </div>

          <div style="margin: 10px 0">
            <span style="font-size: 18px">备注：</span>{{ data.remarke }}
          </div>
          <MyDialog
            :logisticsNum="data.logisticsNum"
            :logisticsName="data.logisticsName"
          ></MyDialog>
          <div class="technician">
            <div class="technician_title">技术支持</div>
            <div
              class="staff"
              v-for="(item, index) in data.technologyList"
              :key="index"
            >
              <p class="name">{{ item.name }}</p>
              <p class="phone">{{ item.phone }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="message">
        <div class="title">返单原因</div>
        <div class="message-list">
          <div name="1">
            <div class="message-operation">
              <div class="operation-item">
                <el-avatar
                  :size="60"
                  src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
                ></el-avatar>
                <el-input
                  disabled
                  class="form-textarea"
                  type="textarea"
                  :rows="5"
                  v-model="backWhy"
                ></el-input>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="evaluation" >
        <!-- 配件留言 -->
        <el-tabs
          v-model="tabType"
          @tab-click="
            mInfo = '';
            replyInfo = '';
          "
        >
          <el-tab-pane
            class="explain"
            label="配件单申请"
            name="1"
            v-if="backData.status == 2"
          >
            <div>
              <el-form
                ref="installOrderMassage"
                :inline="true"
                :model="accessories"
                :rules="rules"
                label-position="left"
                label-width="90px"
              >
                <el-form-item
                  label="配件名称"
                  style="width: 400px"
                  prop="accessoriesName"
                >
                  <el-input
                    v-model="accessories.accessoriesName"
                    placeholder="请输入配件名称"
                    style="width: 250px"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  required
                  label="配件数量"
                  style="width: 400px"
                  prop="accessoriesNum"
                >
                  <el-input
                    type="number"
                    v-model="accessories.accessoriesNum"
                    placeholder="请输入配件数量"
                    style="width: 250px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="申请原因" style="width: 100%">
                  <el-input
                    class="cause"
                    type="textarea"
                    resize="none"
                    v-model="accessories.accessoriesWhy"
                    placeholder="填写备注原因"
                    style="width: 660px"
                  ></el-input>
                </el-form-item>
                <el-form-item label="上传凭证" prop="accessoriesImg">
                  <el-upload
                    action="*"
                    :http-request="uploadImg"
                    list-type="picture-card"
                    :on-remove="handleAvatarRemove"
                    :before-upload="beforeAvatarUpload"
                    accept="image/*"
                    :file-list="filelist"
                  >
                    <i class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible"> </el-dialog>
                </el-form-item>
              </el-form>
              <div class="button">
                <el-button type="primary" @click="accessories_save()">
                  提交
                </el-button>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane class="explain" label="申请列表" name="2">
            <!-- <div class="mountings"> -->
            <el-table
              :data="details_list"
              @cell-click="cellClick"
              style="width: 100%"
              height="467"
            >
              <el-table-column
                align="center"
                prop="accessoriesName"
                label="配件名称"
                width="width"
                show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="accessoriesNum"
                label="配件数量"
                width="width"
              >
              </el-table-column>
              <el-table-column
                align="center"
                show-overflow-tooltip
                prop="accessoriesWhy"
                label="申请原因"
                width="width"
              >
              </el-table-column>
              <el-table-column
                align="center"
                prop="oldReturn"
                label="是否返件"
                width="width"
              >
                <template v-slot="{ row }">
                  <span style="color: #f56c6c" v-if="row.oldReturn == 1 && row.status==2"
                    >是(需要处理)</span
                  >
                  <span v-else-if="row.oldReturn == 0 && row.status==2">否</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                align="center"
                prop="status"
                label="审核状态"
                width="width"
              >
                <template v-slot="{ row }">
                  <span
                    :style="{ color: status_color[row.status] }"
                    style="cursor: pointer;"
                    >{{ status[row.status] }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
    <!-- 配件单详情 -->
  </div>
</template>

<script>
import MyDialog from "./logistics/index.vue";

export default {
  components: {
    MyDialog
  },
  data() {
    var checkNum = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("配件数量不能为空"));
      } else {
        if (!isNaN(value)) {
          callback();
        } else {
          return callback(new Error("配件数量必须为数字"));
        }
      }
    };
    return {
      isReoder: "0",
      backWhy: "",
      visibleTip: false,
      tabType: "1",
      type: 1,
      show_accessories: false,
      accessories_info: {
        status: 1
      },
      backData: {},
      getLogistics: [],
      messageId: null,
      replyInfo: "",
      mInfo: "",
      status: {
        1: "审核中",
        2: "通过",
        3: "失败"
      },
      state: "",
      status_color: {
        1: "#F56C6C",
        2: "#409EFF",
        3: "#F56C6C"
      },
      //  2待签收  3服务中  4已预约  5已完成  6已结算

      list: [
        {
          src: require("@/assets/img/install/img-install-details-1.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-1.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "待签收",
          time: "2021-08-26",
          isArrive: true,
          path: "/personal-center/install/signit"
        },
        {
          src: require("@/assets/img/install/img-install-details-2.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-2.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "服务中",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/inservice"
        },
        {
          src: require("@/assets/img/install/img-install-details-3.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-3.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已预约",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/appointment"
        },
        {
          src: require("@/assets/img/install/img-install-details-4.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已完成",
          time: "",
          isArrive: false,
          path: "/personal-center/install/complete"
        },
        {
          src: require("@/assets/img/install/img-install-details-5.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-5.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "已结算",
          time: "2021-08-26",
          isArrive: false,
          path: "/personal-center/install/settlement"
        }
      ],
      title: "同意",
      list1: [
        {
          src: require("@/assets/img/install/img-install-details-2.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-2.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "提交配件申请",
          time: "",
          isArrive: true,
          path: "/personal-center/install/inservice"
        },
        {
          src: require("@/assets/img/install/img-install-details-3.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-3.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "申请审核中",
          time: "",
          isArrive: false,
          path: "/personal-center/install/appointment"
        },
        {
          src: require("@/assets/img/install/img-install-details-4.png"),
          src_sel: require("@/assets/img/install/img-install-details-sel-4.png"),
          arrows: require("@/assets/img/install/img-install-details-arrows.png"),
          arrows_sel: require("@/assets/img/install/img-install-details-sel-arrows.png"),
          title: "同意",
          time: "",
          isArrive: false,
          path: "/personal-center/install/complete"
        }
      ],
      form: {},
      // 留言
      oid: null,
      dialogVisible: false,
      //  配件单
      accessories: {
        accessoriesName: "",
        accessoriesNum: "",
        accessoriesWhy: "",
        accessoriesImg: []
      },

      //  配件
      Return: {
        //  返件物流凭证
        accessoriesLogisticsImg: "",
        //  返件物流单号
        accessoriesLogisticsNum: ""
        //
      },
      // 返件信息
      returnData: {
        returnAddress: "",
        accessoriesDetail: "",
        logisticsName: "",
        logisticsNum: "",
        remarke: "",
        accessoriesImg: []
      },
      returnDisal: false,

      //  返件信息

      rules: {
        accessoriesName: [
          { required: true, message: "配件名称不能为空", trigger: "blur" }
        ],
        accessoriesNum: [{ validator: checkNum, trigger: "blur" }],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
      },
      return_rules: {
        logisticsName: [
          { required: true, message: "物流名称不能为空", trigger: "blur" }
        ],
        logisticsNum: [
          { required: true, message: "物流单号不能为空", trigger: "blur" }
        ],
        accessoriesImg: [
          { required: true, message: "请您上传凭证", trigger: "blur" }
        ]
        // returnAddress: [
        //   { required: true, message: "返件地址不能为空", trigger: "blur" }
        // ],
        // accessoriesDetail: [
        //   { required: true, message: "返件要求不能为空", trigger: "blur" }
        // ]
      },

      personnel: [],
      data: {},
      reverse: true,
      activities: [],
      value: 0,
      // 配件类型
      title_list: [
        "配件名称",
        "配件数量",
        "申请原因",
        "审核状态"
        // {
        //   name: "配件名称",
        //   quantity: "配件数量",
        //   cause: "申请原因",
        //   audit: "审核状态"
        // }
      ],
      // 配件信息详情
      details_list: [],
      filelist: [],
      evaluate: {}
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  watch: {
    tabType(val) {
      if (val == 2) {
        this.query_accessoriesOrderList();
      }
    }
    // "data.status": {
    //   deep: true,
    //   handler(val) {
    //     if (val != 4) {
    //       this.tabType = "2";
    //       this.query_accessoriesOrderList();
    //     }
    //   }
    // }
  },
  mounted() {
    let type = this.$route.query.type;
    this.oid = this.$route.query.oid;
    this.type = Number(type);
    this.backOrderDetail();

    // this.query_OrderEvaluation();
  },
  methods: {
    /**
     * 配件上传图片
     */
    uploadImg(param) {
      let file = param.file;
      let form = new FormData();
      form.append("file", file);
      const loading = this.$loading({
        lock: true,
        text: "上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .uploadImg(form)
        .then(res => {
          this.accessories.accessoriesImg.push({
            name: file.name,
            showPath: this.$utils.picturePrefix(res.data.filePath),
            path: res.data.filePath
          });
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },

    /**
     * 配件删除图片
     */
    handleAvatarRemove(file) {
      this.accessories.accessoriesImg = this.accessories.accessoriesImg.filter(
        e => {
          return e.name != file.name;
        }
      );
    },

    /**
     * 上传前图片判断
     */
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 10;
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 10MB!");
      }
      return isLt2M;
    },

    /**
     * 获取物流信息
     */
    query_getLogistics() {
      this.$api
        .getLogistics({
          logisticsNumber: this.accessories_info.logisticsNum
          // logisticsNumber: 12345678
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.getLogistics = data;
          }
        });
    },
    /**
     * 提交派件
     */
    accessories_save() {
      this.$refs.installOrderMassage.validate(valid => {
        if (valid) {
          let img = [];
          this.accessories.accessoriesImg.forEach(e => {
            img.push(e.path);
          });
          this.$api
            .installationOrderAccessories({
              installationId: Number(this.data.id),
              type: 2,
              backOrderId: this.backData.id,
              customerId: this.userId,
              accessoriesImg: img.join(","),
              accessoriesNum: Number(this.accessories.accessoriesNum),
              accessoriesWhy: this.accessories.accessoriesWhy,
              accessoriesName: this.accessories.accessoriesName
            })
            .then(res => {
              this.accessories.accessoriesImg = [];
              this.accessories.accessoriesNum = " ";
              this.accessories.accessoriesName = "";
              this.accessories.accessoriesWhy = "";
              this.filelist = [];
              let { code, msg } = res;
              if (code == 200) {
                this.$message({
                  type: "success",
                  message: msg,
                  duration: 2000
                });
              }
            });
        }
      });
    },

    /**
     *  获取配件单列表
     */
    query_accessoriesOrderList() {
      this.$api
        .accessoriesOrderList({
          customerId: this.userId,
          num: 10000,
          page: 1,
          backOrderId: this.oid
        })
        .then(res => {
          let { data, code } = res;
          if (code == 200) {
            this.details_list = data.records;
          }
        });
    },
    /**
     * 获取配件详情
     */
    cellClick(row) {
      this.$router.push(
        `/personal-center/install/accessories-details?id=${row.id}&type=${this.type}&oid=${this.oid}&dtype=1`
      );
    },

    /**
     *  获取评价列表
     */
    query_OrderEvaluation() {
      this.$api.getInstallationOrderDetail(this.oid).then(res => {
        let { code, data } = res;
        if (code == 200) {
          this.evaluate = data;
        }
        console.log("获取评价列表", res);
      });
    },

    // 获取返单详情接口
    backOrderDetail() {
      this.$api
        .backOrderDetail({
          id: this.oid
          // id: 22
        })
        .then(res => {
          let { code, data } = res;
          if (code == 200) {
            this.backData = data;
            if(data.status == 3) {
              this.tabType = "2"
            }
            this.backWhy = data.backWhy;
            this.data = data.installationOrder;
            if (this.data.auditFile) {
              this.data.auditFile = this.data.auditFile.split(",");
            }
          }
        });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
